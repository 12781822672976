<script setup lang="ts">

</script>

<template>
	<div class="w-screen py-3 2xl:px-96 xl:px-64 lg:px-32 px-12 overflow-x-hidden">
		<slot></slot>
	</div>
</template>

<style scoped>

</style>